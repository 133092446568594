const stateOptions = [
    { value: 'ca', label: 'California' },
    { value: 'co', label: 'Colorado' },
    { value: 'fl', label: 'Florida' },
    { value: 'ky', label: 'Kentucky' },
    { value: 'il', label: 'Illinois'},
    { value: 'la', label: 'Louisiana' },
    { value: 'ma', label: 'Massachusetts' },
    { value: 'me', label: 'Maine' },
    { value: 'md', label: 'Maryland' },
    { value: 'mi', label: 'Michigan' },
    { value: 'mn', label: 'Minnesota' },
    { value: 'mt', label: 'Montana' },
    { value: 'nc', label: 'North Carolina' },
    { value: 'ny', label: 'New York'},
    { value: 'or', label: 'Oregon' },
    { value: 'pa', label: 'Pennsylvania' },
    { value: 'sc', label: 'South Carolina' },
    { value: 'wa', label: 'Washington' },
    { value: 'wi', label: 'Wisconsin' },
    { value: 'wy', label: 'Wyoming' }
  ]

  const localityOptions = [
    { value: 'adrian', label: 'Adrian, MI' },
    { value: 'albion', label: 'Albion, MI' },
    { value: 'algonac', label: 'Algonac, MI' },
    { value: 'allen_park', label: 'Allen Park, MI' },
    { value: 'alma', label: 'Alma, MI' },
    { value: 'alpena', label: 'Alpena, MI' },
    { value: 'alpena_county', label: 'Alpena County, MI' },
    { value: 'ann_arbor', label: 'Ann Arbor, MI' },
    { value: 'ann_arbor_public_schools', label: 'Ann Arbor Public Schools, MI' },
    { value: 'apex', label: 'Apex, NC' },
    { value: 'aspen', label: 'Aspen, CO' },
    { value: 'attleboro', label: 'Attleboro, MA' },
    { value: 'auburn_hills', label: 'Auburn Hills, MI' },
    { value: 'bangor_me', label: 'Bangor, ME' },
    { value: 'barry_county', label: 'Barry County, MI' },
    { value: 'bay_city', label: 'Bay City, MI' },
    { value: 'bay_county', label: 'Bay County, MI' },
    { value: 'beltrami_county', label: 'Beltrami County, MN' },
    { value: 'benton_harbor', label: 'Benton Harbor, MI' },
    { value: 'berkeley_county', label: 'Berkeley County, SC'},
    { value: 'berkley', label: 'Berkley, MI' },
    { value: 'birmingham', label: 'Birmingham, MI' },
    { value: 'bloomfield_township', label: 'Bloomfield Township, MI' },
    { value: 'boston', label: 'Boston, MA' },
    { value: 'boston_city_council', label: 'Boston City Council, MA' },
    { value: 'boulder', label: 'Boulder, CO' },
    { value: 'boyne_city', label: 'Boyne City, MI' },
    { value: 'brighton', label: 'Brighton, MI' },
    { value: 'brockton', label: 'Brockton, MA' },
    { value: 'brockton_school_committee', label: 'Brockton School Committee, MA' },
    { value: 'buchanan', label: 'Buchanan, MI' },
    { value: 'cadillac', label: 'Cadillac, MI' },
    { value: 'calaveras_county', label: 'Calaveras County, CA'},
    { value: 'calhoun_county', label: 'Calhoun County, MI' },
    { value: 'carrboro', label: 'Carrboro, NC' },
    { value: 'cary', label: 'Cary, NC' },
    { value: 'casper', label: 'Casper, WY' },
    { value: 'cedar_springs', label: 'Cedar Springs, MI' },
    { value: 'charlotte', label: 'Charlotte, MI' },
    { value: 'cheboygan', label: 'Cheboygan, MI' },
    { value: 'chelsea', label: 'Chelsea, MI' },
    { value: 'chippewa_county', label: 'Chippewa County, MI' },
    { value: 'clark_county', label: 'Clark County, WA'},
    { value: 'clarkston', label: 'Clarkston, MI' },
    { value: 'clawson', label: 'Clawson, MI' },
    { value: 'cottrellville', label: 'Cottrellville, MI' },
    { value: 'clayton_nc', label: 'Clayton, NC' },
    { value: 'dearborn_heights', label: 'Dearborn Heights, MI' },
    { value: 'dearborn', label: 'Dearborn, MI' },
    { value: 'del_norte_county', label: 'Del Norte County, CA' },
    { value: 'delta_county', label: 'Delta County, MI'},
    { value: 'detroit', label: 'Detroit' },
    { value: 'dorchester_district_two', label: 'Dorchester School District 2, SC' },
    { value: 'durham_public_schools', label: 'Durham Public Schools, NC' },
    { value: 'east_bay', label: 'East Bay, MI' },
    { value: 'east_china', label: 'East China, MI' },
    { value: 'east_lansing', label: 'East Lansing, MI' },
    { value: 'eastpointe', label: 'Eastpointe, MI' },
    { value: 'elmwood', label: 'Elmwood, MI' },
    { value: 'el_paso_county', label: 'El Paso County, CO'},
    { value: 'escanaba', label: 'Escanaba, MI' },
    { value: 'everett_school_committee', label: 'Everett School Committee, MA' },
    { value: 'fall_river_school_committee', label: 'Fall River School Committee, MA' },
    { value: 'farmington_hills', label: 'Farmington Hills, MI' },
    { value: 'ferndale', label: 'Ferndale, MI' },
    { value: 'ferrysburg', label: 'Ferrysburg, MI' },
    { value: 'flagler_county', label: 'Flagler County, FL'},
    { value: 'flint', label: 'Flint, MI' },
    { value: 'flint_community_schools', label: 'Flint Community Schools, MI' },
    { value: 'fda_advisory_committees', label: 'FDA Advisory Committees' },
    { value: 'fraser', label: 'Fraser, MI' },
    { value: 'fresno_county', label: 'Fresno County, CA' },
    { value: 'fuquay-varina', label: 'Fuquay-Varina, NC' },
    { value: 'garden_city', label: 'Garden City, MI' },
    { value: 'garfield', label: 'Garfield, MI' },
    { value: 'garner', label: 'Garner, NC' },
    { value: 'georgetown_township', label: 'Georgetown Township, MI' },
    { value: 'gladstone', label: 'Gladstone, MI' },
    { value: 'grand_blanc', label: 'Grand Blanc, MI' },
    { value: 'grand_haven', label: 'Grand Haven, MI' },
    { value: 'grand_ledge', label: 'Grand Ledge, MI' },
    { value: 'grand_rapids', label: 'Grand Rapids, MI' },
    { value: 'grand_rapids_public_schools', label: 'Grand Rapids Public Schools, MI' },
    { value: 'grand_traverse_county', label: 'Grand Traverse County, MI' },
    { value: 'greenville', label: 'Greenville, NC' },
    { value: 'greenville_county_schools', label: 'Greenville County Schools, SC' },
    { value: 'grosse_pointe_shores', label: 'Grosse Pointe Shores, MI' },
    { value: 'hamtramck', label: 'Hamtramck, MI' },
    { value: 'hastings', label: 'Hastings, MI' },
    { value: 'hazel_park', label: 'Hazel Park, MI' },
    { value: 'hillsdale', label: 'Hillsdale, MI' },
    { value: 'hillsborough_nc', label: 'Hillsborough, NC' },
    { value: 'holland', label: 'Holland, MI' },
    { value: 'hudsonville', label: 'Hudsonville, MI' },
    { value: 'humboldt_county', label: 'Humboldt County, CA'},
    { value: 'huntington_woods', label: 'Huntington Woods, MI' },
    { value: 'inkster', label: 'Inkster, MI' },
    { value: 'iron_mountain', label: 'Iron Mountain, MI' },
    { value: 'ironwood', label: 'Ironwood, MI' },
    { value: 'jackson_city_mi', label: 'City of Jackson, MI' },
    { value: 'jackson_county', label: 'Jackson County, MI' },
    { value: 'jacksonville', label: 'Jacksonville, NC' },
    { value: 'johnston_county_public_schools', label: 'Johnston County Public Schools, NC' },
    { value: 'kalamazoo', label: 'Kalamazoo, MI' },
    { value: 'kalamazoo_county', label: 'Kalamazoo County, MI' },
    { value: 'kane_county', label: 'Kane County, IL' },
    { value: 'keego_harbor', label: 'Keego Harbor, MI' },
    { value: 'kent_county', label: 'Kent County, MI' },
    { value: 'kentucky_legislative_committees', label: 'Kentucky Legislature - Committee Meetings' },
    { value: 'kings_county', label: 'Kings County, CA' },
    { value: 'kinston', label: 'Kinston, NC' },
    { value: 'knightdale', label: 'Knightdale, NC' },
    { value: 'lake_lure', label: 'Lake Lure, NC' },
    { value: 'lansing', label: 'Lansing, MI' },
    { value: 'lansing_school_district', label: 'Lansing School District, MI' },
    { value: 'lapeer', label: 'Lapeer, MI' },
    { value: 'larimer_county', label: 'Larimer County, CO' },
    { value: 'lathrup_village', label: 'Lathrup Village, MI' },
    { value: 'lawrence_city_council', label: 'Lawrence City Council, MA'},
    { value: 'lawrence_public_schools', label: 'Lawrence Public Schools, MA' },
    { value: 'leelanau_county', label: 'Leelanau County, MI' },
    { value: 'lincoln_park', label: 'Lincoln Park, MI' },
    { value: 'livingston_county', label: 'Livingston County, MI' },
    { value: 'livingston_parish', label: 'Livingston Parish, LA' },
    { value: 'livonia', label: 'Livonia, MI' },
    { value: 'lowell_ma', label: 'Lowell, MA' },
    { value: 'lowell_school_committee', label: 'Lowell School Committee, MA' },
    { value: 'lowell', label: 'Lowell, MI' },
    { value: 'ludington', label: 'Ludington, MI' },
    { value: 'mackinac_bridge_authority', label: 'Mackinac Bridge Authority, MI'},
    { value: 'mackinac_straits_corridor_authority', label: 'Mackinac Straits Corridor Authority, MI'},
    { value: 'macomb', label: 'Macomb County, MI' },
    { value: 'madison_heights', label: 'Madison Heights, MI' },
    { value: 'malden', label: 'Malden, MA' },
    { value: 'manistee', label: 'Manistee, MI' },
    { value: 'marlborough', label: 'Marlborough, MA' },
    { value: 'marlborough_school_committee', label: 'Marlborough School Committee, MA'},
    { value: 'maryland_transportation_authority', label: 'Maryland Transportation Authority, MD'},
    { value: 'marine_city', label: 'Marine City, MI' },
    { value: 'marquette', label: 'Marquette, MI' },
    { value: 'marquette_county', label: 'Marquette County, MI' },
    { value: 'mason', label: 'Mason, MI' },
    { value: 'massachusetts_post_commission', label: 'Massachusetts POST Commission, MA'},
    { value: 'massachusetts_governors_council', label: 'Massachusetts Governor\'s Council, MA' },
    { value: 'michigan_state_transportation_commission', label: 'Michigan State Transportation Commission' },
    { value: 'midland', label: 'Midland, MI' },
    { value: 'midland', label: 'Midland' },
    { value: 'milan', label: 'Milan, MI' },
    { value: 'missoula_county', label: 'Missoula County, MT' },
    { value: 'monroe', label: 'Monroe, MI' },
    { value: 'mount_clemens', label: 'Mount Clemens, MI' },
    { value: 'mt_pleasant', label: 'Mt. Pleasant, MI' },
    { value: 'multnomah_county', label: 'Multnomah County, OR' },
    { value: 'muskegon', label: 'Muskegon, MI' },
    { value: 'muskegon_county', label: 'Muskegon County, MI' },
    { value: 'natrona_county_school_district', label: 'Natrona County School District, WY' },
    { value: 'new_bern', label: 'New Bern, NC' },
    { value: 'new_orleans', label: 'New Orleans, LA' },
    { value: 'NOLA_public_schools', label: 'NOLA Public Schools, LA' },
    { value: 'north_bend', label: 'North Bend, OR' },
    { value: 'norfolk_ma', label: 'Norfolk, MA' },
    { value: 'norton', label: 'Norton, MA' },
    { value: 'norway', label: 'Norway, MI' },
    { value: 'oak_park', label: 'Oak Park, MI' },
    { value: 'oakland_county', label: 'Oakland County, MI' },
    { value: 'olmstead_county', label: 'Olmstead County, MN' },
    { value: 'orange_county', label: 'Orange County, NY' },
    { value: 'oswego_county', label: 'Oswego County, NY'},
    { value: 'ottawa_county', label: 'Ottawa County, MI' },
    { value: 'palo_alto', label: 'Palo Alto, CA' },
    { value: 'perry', label: 'Perry, MI' },
    { value: 'pittsburgh', label: 'Pittsburgh, PA' },
    { value: 'pittsburgh_planning_commission', label: 'Pittsburgh City Planning Commission' },
    { value: 'urban_redevelopment_authority_of_pittsburgh', label: 'Urban Redevelopment Authority of Pittsburgh' },
    { value: 'pleasant_ridge', label: 'Pleasant Ridge, MI' },
    { value: 'plymouth', label: 'Plymouth, MI' },
    { value: 'pontiac', label: 'Pontiac, MI' },
    { value: 'port_huron', label: 'Port Huron, MI' },
    { value: 'portage', label: 'Portage, MI' },
    { value: 'raleigh', label: 'Raleigh, NC' },
    { value: 'rdu_airport_authority', label: 'Raleigh Durham Airport Authority, NC' },
    { value: 'richmond', label: 'Richmond, MI' },
    { value: 'riverview', label: 'Riverview, MI' },
    { value: 'rochester', label: 'Rochester, NY' },
    { value: 'rochester_hills', label: 'Rochester Hills, MI' },
    { value: ['rochester_mn', 'rochester_mn_rss'], label: 'Rochester, MN' },
    { value: 'rochester_public_schools', label: 'Rochester Public Schools, MN' },
    { value: 'rolesville', label: 'Rolesville, NC'},
    { value: 'romulus', label: 'Romulus, MI' },
    { value: 'roscommon', label: 'Roscommon County, MI' },
    { value: 'roseville', label: 'Roseville, MI' },
    { value: 'royal_oak', label: 'Royal Oak, MI' },
    { value: 'saginaw', label: 'Saginaw, MI' },
    { value: 'sanilac_county', label: 'Sanilac County, MI' },
    { value: 'sault_sainte_marie', label: 'Sault Sainte Marie, MI' },
    { value: 'sawyer_county', label: 'Sawyer County, WI' },
    { value: 'seekonk', label: 'Seekonk, MA' },
    { value: 'smithfield', label: 'Smithfield, NC' },
    { value: 'south_lyon', label: 'South Lyon, MI' },
    { value: 'southfield', label: 'Southfield, MI' },
    { value: 'st_clair_county', label: 'St. Clair County, MI' },
    { value: 'st_clair_shores', label: 'St. Clair Shores, MI' },
    { value: 'st_clair', label: 'St. Clair, MI' },
    { value: 'stanton', label: 'Stanton, MI' },
    { value: 'sterling_heights', label: 'Sterling Heights, MI' },
    { value: 'sylvan_lake', label: 'Sylvan Lake, MI' },
    { value: 'taylor', label: 'Taylor, MI' },
    { value: 'trenton', label: 'Trenton, MI' },
    { value: 'traverse_city', label: 'Traverse City, MI' },
    { value: 'troy', label: 'Troy, MI' },
    { value: 'utica', label: 'Utica, MI' },
    { value: 'university_of_michigan_board_of_regents', label: 'University of Michigan Board of Regents, MI'},
    { value: 'van_buren_county', label: 'Van Buren County, MI' },
    { value: 'wake_county_public_schools', label: 'Wake County Public Schools, NC' },
    { value: 'walker', label: 'Walker, MI' },
    { value: 'warren', label: 'Warren, MI' },
    { value: 'washtenaw_county', label: 'Washtenaw County, MI' },
    { value: 'wayne_county', label: 'Wayne County, MI' },
    { value: 'wayne', label: 'Wayne, MI' },
    { value: 'westland', label: 'Westland, MI' },
    { value: 'west_baton_rouge', label: 'West Baton Rouge, LA' },
    { value: 'white_cloud', label: 'White Cloud, MI' },
    { value: 'williamston', label: 'Williamston, MI' },
    { value: 'worcester_school_committee', label: 'Worcester School Committee, MA' },
    { value: 'wixom', label: 'Wixom, MI' },
    { value: 'wyandotte', label: 'Wyandotte, MI' },
    { value: 'wyoming', label: 'Wyoming, MI' },
    { value: 'ypsilanti', label: 'Ypsilanti, MI' },
    { value: 'zebulon', label: 'Zebulon, NC'}
  ]

  export {stateOptions, localityOptions}