// base react
import React, { useState, useEffect } from "react";

import * as ElasticAppSearch from "@elastic/app-search-javascript";

// helper functions to configure search
import {
  getConfig,
} from "../../config/config-helper";

// extended styling
import '../../index.css';
import Highlighter from 'react-highlight-words';

// configure search
const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

const client = ElasticAppSearch.createClient({
    hostIdentifier,
    searchKey,
    engineName,
    endpointBase,
});


function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const FullTranscript = (props) => {

  const [allTranscript, setAllTranscript] = useState([])
  const [selectedRange, setSelectedRange] = useState({ start: null, end: null })
  const [isSelecting, setIsSelecting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [embedId, setEmbedId] = useState(null)
  const [copyFeedback, setCopyFeedback] = useState(false)

  useEffect(() => {

      const videoUrl = props.resultdata.video_url.raw
      const allTrans = []
      const options = {
        filters: { video_url: videoUrl, },
        sort: { "segment_start": "asc" },
        result_fields: { transcript: { raw: {} }, timestamp_url: { raw: {} } , segment_start: { raw: {} } },
        page: { size: 500 }
      };
      
      client
          .search("", options)
          .then(resultList => {
              resultList.results.forEach(result => {
              const oneTranscript = {}
              oneTranscript.transcript = result.getRaw("transcript")
              oneTranscript.timestamp = result.getRaw("segment_start")
              oneTranscript.url = result.getRaw("timestamp_url")
              allTrans.push(oneTranscript)
              });
              setAllTranscript(allTrans)
          })
          .catch(error => {
              console.log(`error: ${error}`);
          });
      
  }, [props.resultdata.video_url.raw])

  const handleSegmentClick = (index) => {
    if (!isSelecting) {
      setIsSelecting(true)
      setSelectedRange({ start: index, end: index })
    } else {
      setIsSelecting(false)
      if (index < selectedRange.start) {
        setSelectedRange({ ...selectedRange, end: selectedRange.start, start: index })
      } else {
        setSelectedRange({ ...selectedRange, end: index })
      }
    }
  }

  const createEmbed = async () => {
    if (!selectedRange.start && !selectedRange.end) return

    // Get the selected segments from our existing transcript data
    const selectedSegments = allTranscript.slice(
      selectedRange.start,
      selectedRange.end + 1
    ).map(segment => ({
      timestamp: segment.timestamp,
      text: segment.transcript
    }))

    // Get the video URL from the result data
    const videoUrl = props.resultdata.video_url?.raw || 
                    props.resultdata.timestamp_url?.raw?.split('&t=')[0]

    try {
      const response = await fetch('/user_auth/embed/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
          'Authorization': `Token ${localStorage.getItem('auth-token')}`
        },
        body: JSON.stringify({
          video_url: videoUrl,
          start_time: allTranscript[selectedRange.start].timestamp,
          end_time: selectedRange.end < allTranscript.length - 1 
            ? allTranscript[selectedRange.end + 1].timestamp 
            : null,
          segments: selectedSegments
        })
      })

      const data = await response.json()
      if (response.ok) {
        setEmbedId(data.id)
        setShowModal(true)
        setSelectedRange({ start: null, end: null })
      } else {
        throw new Error(data.error)
      }
    } catch (error) {
      console.error('Error creating embed:', error)
      alert('Failed to create embed')
    }
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setShowModal(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <div className="FullTranscript">
      { allTranscript.length === 0 ? (
        <div>Sorry, full transcript is not available</div>
      ):(
        <>
          {selectedRange.start !== null && (
            <div className="mb-4 flex justify-between items-center">
              <div className="text-gray-600 dark:text-gray-400">
                Selected range: {allTranscript[selectedRange.start].timestamp} - {
                  selectedRange.end < allTranscript.length - 1 
                    ? allTranscript[selectedRange.end + 1].timestamp 
                    : allTranscript[allTranscript.length - 1].timestamp
                }
              </div>
              <button
                onClick={createEmbed}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Create Embed
              </button>
            </div>
          )}
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full table-auto text-sm text-left text-gray-500 dark:text-gray-400">
              { props.resultdata.timestamp_url.raw.includes("youtube") ? (
                <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400"><b>Tip:</b> click on the timestamp to view the corresponding video section</p>
                </caption>
              ):(null)
              }
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">Timestamp</th>
                  <th scope="col" className="px-6 py-3">Transcript</th>
                </tr>
              </thead>
              <tbody>
                { allTranscript.map((item, index) => {
                  const isSelected = selectedRange.start !== null && 
                    index >= Math.min(selectedRange.start, selectedRange.end) && 
                    index <= Math.max(selectedRange.start, selectedRange.end)

                  return (
                    <tr 
                      key={index} 
                      onClick={() => handleSegmentClick(index)}
                      className={`cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700 
                        ${isSelected ? 'bg-blue-50 dark:bg-blue-900' : ''}`}
                    >
                      { props.resultdata.timestamp_url.raw.includes("youtube") ? (
                        <td className="px-6 py-4"><a href={ item.url } className="ml-2 font-regular text-s text-theme-hyperlink" target="_blank" rel="noopener noreferrer">{ item.timestamp }</a></td>
                      ):(
                        <td className="px-6 py-4">{ item.timestamp }</td>
                      )
                      }
                      { item.transcript === '0' ? (
                        <td className="px-6 py-4">no speech</td>
                        ):(
                          <td className="px-6 py-4">
                            <Highlighter
                              searchWords={props.searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                              autoEscape={true}
                              textToHighlight={item.transcript}
                            />
                          </td>
                        )
                      }
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Embed Code
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md">
                  <code className="text-sm text-gray-800 dark:text-gray-200">
                    {`<iframe src="https://searchminutes.com/embed/${embedId}" width="100%" height="600" frameborder="0"></iframe>`}
                  </code>
                </div>
                <div className="mt-4 flex justify-end items-center gap-2">
                  {copyFeedback && (
                    <span className="text-green-600 dark:text-green-400 text-sm">
                      Copied to clipboard!
                    </span>
                  )}
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `<iframe src="https://searchminutes.com/embed/${embedId}" width="100%" height="600" frameborder="0"></iframe>`
                      )
                      setCopyFeedback(true)
                      setTimeout(() => setCopyFeedback(false), 2000) // Hide after 2 seconds
                    }}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Copy Code
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
        )
      }
    </div>
  )
};

export default FullTranscript;