import React, { useState, useEffect } from 'react';
import { getCookie } from "../../getCookie";

const Channels = () => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [successes, setSuccesses] = useState({});
  const [groupChannels, setGroupChannels] = useState([]);
  const [allChannels, setAllChannels] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [searchQueries, setSearchQueries] = useState({});
  const [selectedStates, setSelectedStates] = useState({});
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState({});
  const [activeTab, setActiveTab] = useState({});
  const [groupUsers, setGroupUsers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState({});

  const clearMessages = (groupId) => {
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[groupId];
      return newErrors;
    });
    setSuccesses(prev => {
      const newSuccesses = { ...prev };
      delete newSuccesses[groupId];
      return newSuccesses;
    });
  };

  useEffect(() => {
    setMounted(true);
    fetchGroupChannels();
    fetchAllChannels();
    return () => setMounted(false);
  }, []);

  const fetchGroupChannels = async () => {
    if (!mounted) return;
    setLoading(true);
    try {
      const res = await fetch("/user_auth/user-group-channels/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        },
      });
      if (!mounted) return;
      const data = await res.json();
      
      if (res.status === 200) {
        setGroupChannels(Array.isArray(data.groups) ? data.groups : []);
      } else if (res.status === 401) {
        window.location.href = "/login";
      } else {
        setErrors(prev => ({
          ...prev,
          global: data.message || 'Failed to fetch group channels'
        }));
      }
    } catch (error) {
      if (!mounted) return;
      console.error('Fetch error:', error);
      setErrors(prev => ({
        ...prev,
        global: 'Failed to communicate with the server.'
      }));
    } finally {
      if (mounted) setLoading(false);
    }
  };

  const fetchAllChannels = async () => {
    fetch("/user_auth/all-channels/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
        Authorization: "Token " + localStorage.getItem("auth-token"),
      },
    })
    .then(res => res.json().then(data => ({ status: res.status, body: data })))
    .then(obj => {
      if (obj.status === 200) {
        setAllChannels(obj.body.channels);
      } else if (obj.status === 401) {
        window.location.href = "/login";
      } else {
        setErrors(prev => ({
          ...prev,
          global: obj.body.message || 'Failed to fetch available channels'
        }));
      }
    })
    .catch(error => {
      console.error('Fetch error:', error);
      setErrors(prev => ({
        ...prev,
        global: 'Failed to communicate with the server.'
      }));
    });
  };

  const handleChannelToggle = async (groupId, channelId) => {
    clearMessages(groupId);
    const group = groupChannels.find(g => g.id === groupId);
    if (!group) return;

    const newChannels = group.channels.includes(channelId)
      ? group.channels.filter(id => id !== channelId)
      : [...group.channels, channelId];

    try {
      setLoading(true);
      const res = await fetch("/user_auth/user-group-channels/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        },
        body: JSON.stringify({
          group_id: groupId,
          channels: newChannels
        }),
      });

      const data = await res.json();
      
      if (res.status === 201) {
        setSuccesses(prev => ({
          ...prev,
          [groupId]: 'Channel configuration updated successfully'
        }));
        setSearchQueries(prev => ({
          ...prev,
          [groupId]: ''
        }));
        setSelectedStates(prev => ({
          ...prev,
          [groupId]: ''
        }));
        fetchGroupChannels();
      } else if (res.status === 401) {
        window.location.href = "/login";
      } else {
        setErrors(prev => ({
          ...prev,
          [groupId]: data.message || 'Failed to update channels'
        }));
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setErrors(prev => ({
        ...prev,
        [groupId]: 'Failed to communicate with the server'
      }));
    } finally {
      setLoading(false);
    }
  };

  const deleteChannel = async (groupId, channelId) => {
    clearMessages(groupId);
    setLoading(true);
    try {
      const res = await fetch(`/user_auth/user-group-channels/${groupId}/${channelId}/`, {
        method: "DELETE",
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        }
      });
      
      if (res.status === 200) {
        setSuccesses(prev => ({
          ...prev,
          [groupId]: 'Channel removed successfully'
        }));
        fetchGroupChannels();
      } else if (res.status === 401) {
        window.location.href = "/login";
      } else {
        const data = await res.json();
        setErrors(prev => ({
          ...prev,
          [groupId]: data.message || 'Failed to remove channel'
        }));
      }
    } catch (error) {
      console.error('Delete error:', error);
      setErrors(prev => ({
        ...prev,
        [groupId]: 'Failed to communicate with the server'
      }));
    } finally {
      setLoading(false);
    }
  };

  const getFilteredChannels = (channels, groupId) => {
    const searchQuery = searchQueries[groupId] || '';
    const selectedState = selectedStates[groupId] || '';
    
    return channels
      .filter(channel => 
        !groupChannels.find(g => g.id === groupId)?.channels.includes(channel.id) &&
        channel.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (!selectedState || channel.state === selectedState)
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const getUniqueStates = () => {
    return [...new Set(allChannels.map(channel => channel.state))].sort();
  };

  const addGroupUser = async (groupId, email) => {
    clearMessages(groupId);
    try {
      const res = await fetch("/user_auth/group-user/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        },
        body: JSON.stringify({
          group_id: groupId,
          email: email,
        }),
      });
      
      const data = await res.json();
      if (res.status === 200) {
        setSuccesses(prev => ({
          ...prev,
          [groupId]: data.message
        }));
        setNewUserEmail(prev => ({ ...prev, [groupId]: '' }));
        fetchGroupUsers(groupId);
      } else {
        setErrors(prev => ({
          ...prev,
          [groupId]: data.message || 'Failed to add user'
        }));
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        [groupId]: 'Failed to add user'
      }));
    }
  };

  const removeGroupUser = async (groupId, email) => {
    clearMessages(groupId);
    try {
      const res = await fetch(`/user_auth/group/${groupId}/user/${encodeURIComponent(email)}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        }
      });
      
      const data = await res.json();
      if (res.status === 200) {
        setSuccesses(prev => ({
          ...prev,
          [groupId]: data.message
        }));
        fetchGroupUsers(groupId);
      } else {
        setErrors(prev => ({
          ...prev,
          [groupId]: data.message || 'Failed to remove user'
        }));
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        [groupId]: 'Failed to remove user'
      }));
    }
  };

  const fetchGroupUsers = async (groupId) => {
    try {
      const res = await fetch(`/user_auth/group/${groupId}/users/`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        },
      });
      
      const data = await res.json();
      if (res.status === 200) {
        setGroupUsers(prev => ({
          ...prev,
          [groupId]: data.users
        }));
      }
    } catch (error) {
      console.error('Failed to fetch group users:', error);
    }
  };

  useEffect(() => {
    Object.entries(activeTab).forEach(([groupId, tab]) => {
      if (tab === 'users') {
        fetchGroupUsers(groupId);
      }
    });
  }, [activeTab]);

  return (
    <div className="flex flex-col bg-white sm:rounded-lg ml-16 md:ml-64 relative">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Channel Configuration</h3>
      </div>

      {errors.global && (
        <div className="px-4 py-3 bg-red-50 text-red-700 border-b border-gray-200">
          {errors.global}
        </div>
      )}

      {loading ? (
        <div className="px-4 py-3 text-gray-500 text-center">
          Loading...
        </div>
      ) : groupChannels.length === 0 ? (
        <div className="px-4 py-3 text-gray-500 text-center">
          No groups available for configuration.
        </div>
      ) : (
        <div className="px-4">
          {groupChannels.map(group => (
            <div key={group.id} className="mb-8 border rounded-lg overflow-hidden">
              <div className="bg-gray-50 px-4 py-3 border-b">
                <h3 className="text-lg font-medium text-gray-900">{group.name}</h3>
                <div className="mt-2 border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8">
                    <button
                      onClick={() => setActiveTab(prev => ({ ...prev, [group.id]: 'channels' }))}
                      className={`${
                        (!activeTab[group.id] || activeTab[group.id] === 'channels')
                          ? 'border-blue-500 text-blue-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                    >
                      Channels
                    </button>
                    <button
                      onClick={() => setActiveTab(prev => ({ ...prev, [group.id]: 'users' }))}
                      className={`${
                        activeTab[group.id] === 'users'
                          ? 'border-blue-500 text-blue-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                    >
                      Users
                    </button>
                  </nav>
                </div>
              </div>

              {errors[group.id] && (
                <div className="px-4 py-3 bg-red-50 text-red-700 border-b">
                  {errors[group.id]}
                </div>
              )}
              {successes[group.id] && (
                <div className="px-4 py-3 bg-green-50 text-green-700 border-b">
                  {successes[group.id]}
                </div>
              )}
              
              <div className="p-4">
                {(!activeTab[group.id] || activeTab[group.id] === 'channels') ? (
                  <>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">Selected Channels</label>
                      <div className="flex flex-wrap gap-2">
                        {group.channels.map(channelId => {
                          const channel = allChannels.find(c => c.id === channelId);
                          return channel ? (
                            <span key={channel.id} className="inline-flex items-center bg-blue-100 text-blue-800 rounded-full px-3 py-1">
                              {channel.name}
                              <button
                                onClick={() => deleteChannel(group.id, channel.id)}
                                className="ml-2 text-blue-600 hover:text-blue-800"
                                disabled={loading}
                              >
                                ×
                              </button>
                            </span>
                          ) : null;
                        })}
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">Add Channels</label>
                      <div className="space-y-2">
                        <input
                          type="text"
                          placeholder="Search channels..."
                          className="block w-full pl-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          value={searchQueries[group.id] || ''}
                          onChange={(e) => {
                            if (e.target.value.trim() && selectedStates[group.id]) {
                              setSelectedStates(prev => ({
                                ...prev,
                                [group.id]: ''
                              }));
                            }
                            setSearchQueries(prev => ({
                              ...prev,
                              [group.id]: e.target.value
                            }));
                          }}
                          disabled={loading}
                        />

                        {(searchQueries[group.id] || '').trim() ? (
                          <div className="mt-1 border rounded-md max-h-60 overflow-y-auto">
                            {getFilteredChannels(allChannels, group.id).length > 0 ? (
                              getFilteredChannels(allChannels, group.id).map(channel => (
                                <button
                                  key={channel.id}
                                  onClick={() => handleChannelToggle(group.id, channel.id)}
                                  className="w-full px-4 py-2 text-left hover:bg-gray-50 flex justify-between items-center border-b last:border-b-0"
                                  disabled={loading}
                                >
                                  <span>{channel.name}</span>
                                  <span className="text-gray-500 text-sm">{channel.state}</span>
                                </button>
                              ))
                            ) : (
                              <div className="px-4 py-2 text-gray-500">No matching channels found</div>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="relative">
                              <button
                                type="button"
                                className="w-full px-4 py-2 text-left bg-white border rounded-md hover:bg-gray-50 flex justify-between items-center"
                                onClick={() => setIsStateDropdownOpen(prev => ({
                                  ...prev,
                                  [group.id]: !prev[group.id]
                                }))}
                                disabled={loading}
                              >
                                {selectedStates[group.id] || 'Or select a state...'}
                                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                              </button>
                              
                              {isStateDropdownOpen[group.id] && (
                                <div className="mt-1 border rounded-md max-h-60 overflow-y-auto">
                                  <button
                                    key="all"
                                    className="w-full px-4 py-2 text-left hover:bg-gray-50 flex justify-between items-center border-b"
                                    onClick={() => {
                                      setSelectedStates(prev => ({...prev, [group.id]: ''}));
                                      setIsStateDropdownOpen(prev => ({...prev, [group.id]: false}));
                                    }}
                                  >
                                    Select a state...
                                  </button>
                                  {getUniqueStates().map(state => (
                                    <button
                                      key={state}
                                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex justify-between items-center border-b last:border-b-0"
                                      onClick={() => {
                                        setSelectedStates(prev => ({...prev, [group.id]: state}));
                                        setIsStateDropdownOpen(prev => ({...prev, [group.id]: false}));
                                      }}
                                    >
                                      {state}
                                    </button>
                                  ))}
                                </div>
                              )}
                            </div>

                            {selectedStates[group.id] && (
                              <div className="mt-1 border rounded-md max-h-60 overflow-y-auto">
                                {getFilteredChannels(allChannels, group.id).map(channel => (
                                  <button
                                    key={channel.id}
                                    onClick={() => handleChannelToggle(group.id, channel.id)}
                                    className="w-full px-4 py-2 text-left hover:bg-gray-50 flex justify-between items-center border-b last:border-b-0"
                                    disabled={loading}
                                  >
                                    <span>{channel.name}</span>
                                    <span className="text-gray-500 text-sm">{channel.state}</span>
                                  </button>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">Current Users</label>
                      <div className="flex flex-wrap gap-2">
                        {groupUsers[group.id]?.map(email => (
                          <span key={email} className="inline-flex items-center bg-blue-100 text-blue-800 rounded-full px-3 py-1">
                            {email}
                            <button
                              onClick={() => removeGroupUser(group.id, email)}
                              className="ml-2 text-blue-600 hover:text-blue-800"
                              disabled={loading}
                            >
                              ×
                            </button>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">Add User by Email</label>
                      <form 
                        onSubmit={(e) => {
                          e.preventDefault();
                          addGroupUser(group.id, newUserEmail[group.id]);
                        }}
                        className="flex gap-2"
                      >
                        <input
                          type="email"
                          placeholder="Enter user email..."
                          value={newUserEmail[group.id] || ''}
                          onChange={(e) => setNewUserEmail(prev => ({
                            ...prev,
                            [group.id]: e.target.value
                          }))}
                          className="block w-full pl-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          disabled={loading}
                        />
                        <button
                          type="submit"
                          disabled={loading || !newUserEmail[group.id]}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                        >
                          Add User
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Channels;