//a component to quickly see the most recent 

import React from "react";
import ResultDetail from "./ResultDetail";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';


const LatestMeetings = (props) => {

    const closeDrawer = props.closeDrawer
    
    const findDrawer = props.findDrawer

    //throw error if resultdata is empty

    if (!props.resultdata || props.resultdata.length === 0) {
        return (
            <p>Sorry can't fetch meetings right now.</p>
        )

      }
      //filter for unique urls

      const uniqueResults = props.resultdata.filter((result, index, self) =>
        index === self.findIndex((t) => (
          t.video_url.raw === result.video_url.raw
        ))
      );

      return (
        <div className="my-2 mx-2 px-2 py-2 border border-theme-darkgray/50 bg-white">
        {/* <div className="font-regular font-light text-sm">LATEST MEETINGS</div> */}
            <Accordion allowZeroExpanded preExpanded={['panel-1']}>
                <AccordionItem uuid="panel-1">
                    <AccordionItemHeading className="font-regular font-light text-sm">LATEST MEETINGS
                        <AccordionItemButton className="text-theme-darkblue font-regular text-sm">
                        <div className="flex flex-row justify-end items-center">
                        <div className="text-right ml-2">Show/Hide</div>
                        <AccordionItemState>
                            {({ expanded }) => (expanded ?
                            <svg className="mr-4" fill="none" width="15px" height="15px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
                            </svg>
                            :
                            <svg className="mr-4" fill="none" width="15px" height="15px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                            </svg>
                            )}
                        </AccordionItemState>
                        </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="mt-5">
                        <div className="pb-1 font-regular font-light text-center text-sm"><p>(Click on meeting for more detail)</p></div>
                        {uniqueResults.slice(0, 10).map(result => 
                        <div class="grid grid-cols-3 font-regular font-light text-m px-1 py-1  bg-opacity-50 hover:bg-theme-blue border border-transparent ">
                            <button 
                                className="text-left font-regular"
                                onClick={() => findDrawer(result.id)}>
                                <div key={result.video_url.raw}>{result.locality.raw.replaceAll("_"," ").replaceAll(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}, {result.state.raw.toUpperCase()}
                                </div>
                            </button>
                            <div className="col-span-2">
                            <button
                                className="text-left font-regular text-theme-darkgray"
                                onClick={() => findDrawer(result.id)}>
                                {result.video_title.raw.replaceAll('"','')}
                            </button>
                            <ResultDetail 
                                resultdata={result} 
                                open={props.state.open} 
                                closeDrawer={closeDrawer} 
                                id={result.id} 
                                currentDrawer={props.state.currentDrawer}
                                month_names={props.month_names}
                                searchTerm={props.searchTerm}>
                            </ResultDetail>
                        </div>
                        </div>
                        )}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
      );

}

export default LatestMeetings;
