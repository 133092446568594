// a landing page for michiganradio.org
// this was configured from initial search page, some leftover appendages remain in comments to be cleaned up in the future

// base react
import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
// import Nav from '../Shared/Nav';
import Results from "./Results";
import LatestMeetings from "./LatestMeetings";

// connector for elastic
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

// react + elastic UI components
import {
  SearchProvider,
  WithSearch,
} from "@elastic/react-search-ui";

// helper functions to configure search
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from "../../config/config-helper";

// extended styling
import '../../index.css';
import Highlighter from 'react-highlight-words';

// locality and state options
import {localityOptions} from '../../LocalityInfo'
import { scryRenderedComponentsWithType } from "react-dom/test-utils"

// configure search
const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});



//initiate search with filter for only first segment of video, so that all most recent videos show up.
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
  initialState: {
    filters: [
      { field: "segment", values: [0] },
      { field: "state", values: ["mi"]}
    ],
    resultsPerPage: 10
  }
};

// dict for date formats
const month_names = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

// react-select config
// const sortOptions = [
//   { value: ['segment', 'asc'], label: 'Meeting timestamp (ascending)' },
//   { value: ['segment', 'desc'], label: 'Meeting timestamp (descending)' },
//   { value: ['upload_date', 'desc'], label: 'Upload date (newest)'},
//   { value: ['upload_date', 'asc'], label: 'Upload date (oldest)'}
// ];

// this styling configures the dropdown menu for locality filter
const dropdownStyle = {
  control: (base, state) => ({
    ...base,
    background: state.isFocused ? 'white' : 'white',
    border: "0px",
    // need these nulls to override the default styles
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null
    }
  }),
  menu: (base) => ({
    ...base,
    border: "0px",
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: (base) => ({
    ...base,
    border: "0px",
    padding: 0
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: '36px', 
    fontWeight: '500',

  }),
  multiValueRemove: (base) => ({
    ...base,
    opacity: '0',
    "&:hover": {
      opacity: '1'
    },
    borderRadius: '0px',
    background: 'white'
    
  }),
  multiValue: (base) => ({
    ...base,
    background: 'white',
  }),
  option: (base) => ({
    ...base,
    border: '0px',
    boxShadow: '0px'
  }),
};

//component is configured to only show results from Michigan

class MichiganRadioPage extends Component {
    state = {
      selectedOption: null,
    };
    constructor(props) {
        super(props);
        this.state = {
          selectedOption: null,
          open: false,
          currentDrawer: null,
          local: [],
          state: ["mi"],
          search: "",
          sort: [],
          sortLabel: "",
          toast: {
            notified: '',
            state: false
          }
        }
    }

  handleLocalChange = (e) => {
    
    this.setState({local: (Array.isArray(e) ? e.map((x) => x.value) : [])});
  };

  handleStateChange = (e) => {
    
    this.setState({state: (Array.isArray(e) ? e.map((x) => x.value) : [])});
  };

  findDrawer = (id) => {
    this.setState({ currentDrawer: id })
    this.setState({ open: true })
  }

  closeDrawer = () => {
    this.setState({ open: false })
  }

  handleToast = (newVal) => {
    this.setState({ toast: newVal })
  }

  render() {

    return (
      <div className="Search relative">
        <SearchProvider config={config}>
            <WithSearch
                mapContextToProps={({ isLoading, searchTerm, setSearchTerm, sort, setSort, value, addFilter, clearFilters,  results }) => ({
                    isLoading,
                    searchTerm,
                    setSearchTerm,
                    sort,
                    setSort,
                    addFilter,
                    clearFilters,
                    value,
                    results
                })}
                >
                {({ isLoading, searchTerm, setSearchTerm, sort, setSort, addFilter, clearFilters, results}) => {
                return (
                  <div className="flex flex-col w-full overflow-auto">
                    {/* LOCALITY FILTER */}
                    <div className="my-5 mx-5 md:w-1/2 flex flex-col ">
                        <div className="mb-2 font-regular font-light text-theme-darkgray text-sm">SHOWING RESULTS FOR:</div>
                        <Select
                            isMulti
                            components={makeAnimated()}
                            styles={dropdownStyle}
                            className="font-regular text-l"
                            onChange={ this.handleLocalChange }
                            value={localityOptions.filter((obj) => this.state.local.includes(obj.value))}
                            options={localityOptions}
                            placeholder="Click to filter by city"
                        />
                    </div>
                    <div className="my-5 mx-8 flex flex-col">
                      {/* SEARCH BAR */}
                      <div className="flex flex-col md:flex md:flex-row items-center justify-center">
                        <input class="mt-5 py-4 px-3 w-2/3 border border-black leading-tight font-regular font-thin placeholder-s placeholder-black"
                            placeholder="Search"
                            autoFocus
                            value={this.state.search}
                            onChange={e => this.setState({search: e.target.value})}
                            onKeyUp={e => { 
                            if (e.key === 'Enter') {
                                clearFilters()
                                // setSort(this.state.sort[0], this.state.sort[1])
                                this.state.local.map(selectedLocality => {
                                return addFilter('locality', selectedLocality, 'any')})
                                this.state.state.map(selectedState => {
                                return addFilter('state', selectedState, 'any')})
                                setSearchTerm(this.state.search, { shouldClearFilters: false })
                            }
                            }}
                        />
                        <button 
                            className={`mt-5 py-4 px-3 w-1/3 bg-gray-600 border border-black leading-tight text-base font-medium text-white`}
                            onClick={() => { 
                            clearFilters()
                            //   setSort(this.state.sort[0], this.state.sort[1])
                            this.state.local.map(selectedLocality => {
                            return addFilter('locality', selectedLocality, 'any')})
                            this.state.state.map(selectedState => {
                                return addFilter('state', "mi", 'any')})
                            setSearchTerm(this.state.search, { shouldClearFilters: false })
                            }
                        }>
                            Search
                        </button>
                      </div>
                      <div className="my-5 font-regular font-light text-left text-sm">
                            For multiple keywords, separate them by comma<br />
                            Showing { results.length } {(results.length !== 1) ? (' results') : (' result')}
                      </div>

                      {/* LATEST MEETINGS */}
                      <div className="flex flex-col">
                        <h2 className="mt-5 mb-2 text-gray-800 text-xl font-bold">Latest Meetings</h2>
                      </div>
                      {!isLoading && results.length !== 0 ? (
                        <LatestMeetings 
                          resultdata={results} 
                          config={config}
                          month_names={month_names}
                          searchTerm={searchTerm}
                          state={this.state} 
                          closeDrawer={this.closeDrawer}
                          findDrawer={this.findDrawer}>
                        </LatestMeetings>
                     ) : (
                        isLoading ? (
                          <p>Getting results...</p>
                        ) : null
                      )} 
                      {/* SEARCH RESULTS */}
                      <div className="flex flex-col">
                        <h2 className="mt-5 mb-2 text-gray-800 text-xl font-bold">Search Results</h2>
                      </div>
                      {!isLoading && <Results
                          results={results}
                          searchTerm={searchTerm}
                          month_names={month_names} 
                          componentState={this.state}
                          findDrawer={this.findDrawer}
                          closeDrawer={this.closeDrawer}
                          currentDrawer={this.state.currentDrawer}
                        /> 
                      } 
                    </div>
                  </div>
                );
              }}
            </WithSearch>
        </SearchProvider>
      </div>
    )
  };
}

export default MichiganRadioPage;