import Select from "react-select";
import { useState } from "react";
import { stateOptions, localityOptions } from "../../LocalityInfo";
import Toast from "./Toast";

const EditPanel = ({ editView, setEdit, updateAlert, createAlert }) => {
  const [newAlert, setNewAlert] = useState({
    id: editView.alert.id,
    keywords: editView.alert.keywords,
    locality: editView.alert.locality,
    state: editView.alert.state,
    // for slack implementation
    // channel: editView.alert.channel,
    frequency: editView.alert.frequency,
    is_notifying: editView.alert.is_notifying,
    day: editView.alert.day,
    time: editView.alert.time,
  });
  const keywords = newAlert.keywords.join(",");
  const locality = newAlert.locality.join(",");
  const state = newAlert.state.join(",");
  const timeNum = newAlert.time.match(/((1[0-2]|0?[1-9]):([0-5][0-9]))/)[2];
  const ampm = newAlert.time.match(/[AaPp][Mm]/)[0];
  const [time, setTime] = useState(timeNum);
  const [ampmState, setAmpmState] = useState(ampm);

  const [isDuplicate, setIsDuplicate] = useState({
    state:false,
    notified: 'duplicate'
  });

  // To display the selected state and locality
  const ensureStateVisibility = (arr, objArray) => {
    let current = [];
    for (const obj of objArray) {
      let shouldPush = false; // check if this object should be added to current array
      if (arr.includes(obj.value)) {
        shouldPush = true;
      }
      if (shouldPush && !current.includes(obj)) {
        current.push(obj);
      }
    }
    return current;
  };

  // Check if duplicate alert has been changed or not
  const checkDuplicate = () => {
    if (
      newAlert.keywords === editView.alert.keywords &&
      newAlert.locality === editView.alert.locality &&
      newAlert.state === editView.alert.state &&
      newAlert.channel === editView.alert.channel &&
      newAlert.frequency === editView.alert.frequency &&
      newAlert.day === editView.alert.day &&
      newAlert.time === editView.alert.time
    ) {
      setIsDuplicate({state:true});
    } else {
      setIsDuplicate({state:false});
      createAlert(newAlert);
    }
  };

  if (editView.edit)
    return (
      <>
        <aside
          id="drawer-contact"
          className="overscroll-contain top-14 right-0 bottom-0 z-40 p-4 overflow-y-auto transition-transform -translate-x-full bg-white w-1/2 fixed shadow-lg"
          tabIndex="-1"
          aria-labelledby="drawer-contact-label"
        >
          <h5
            id="drawer-label"
            className="inline-flex items-center mb-6 text-base font-semibold text-gray-500 uppercase dark:text-gray-400"
          >
            {editView.type === "edit" ? "Edit the alert" : "Add a new alert"}
          </h5>
          <button
            type="button"
            data-drawer-hide="drawer-contact"
            aria-controls="drawer-contact"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={() => {
              setEdit({ edit: false });
            }}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          <div className="mb-2">
            <div className="mb-6">
              <label
                for="keywords"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Keywords
              </label>
              <input
                type="text"
                id="keywords"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={keywords}
                onChange={(e) =>
                  setNewAlert({
                    ...newAlert,
                    keywords: e.target.value.split(","),
                  })
                }
              />
              <p className="text-xs text-gray-500 dark:text-gray-400">
                For mutiple keywords, seperate them by comma
              </p>
            </div>
            <div className="mb-6">
              <label
                for="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Locality
              </label>
              <Select
                isMulti
                closeMenuOnSelect={false}
                defaultValue={ensureStateVisibility(locality, localityOptions)}
                onChange={(locality) => {
                  newAlert.locality = locality.map((item) => item.value);
                }}
                options={localityOptions}
              />
            </div>
            <div className="mb-6">
              <label
                for="state"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                State
              </label>
              <Select
                isMulti
                closeMenuOnSelect={false}
                defaultValue={ensureStateVisibility(state, stateOptions)}
                onChange={(option) => {
                  newAlert.state = option.map((item) => item.value);
                }}
                options={stateOptions}
              />
            </div>
            {/*  for slack implementation */}
            {/* <div className="mb-6">
              <label
                for="channel"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Channel
              </label>
              <select
                name="channel"
                id="channel"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={newAlert.channel}
                onChange={(e) => {
                  setNewAlert({ ...newAlert, channel: e.target.value });
                }}
              >
                <option value="Email">Email</option>
                <option value="Slack">Slack</option>
              </select>
            </div> */}
            {/*  The current setup has frequency and time being set in the profile. 
              This section creates confusion because it isn't being read. Will need to think about whether we even need it.*/}
           
            {/*
            <div className="mb-6">
              <label
                for="frequency"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
              

              Frequency
              </label>
              <select
                name="frequency"
                id="frequency"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={newAlert.frequency}
                onChange={(e) => {
                  setNewAlert({ ...newAlert, frequency: e.target.value, day: e.target.value === "Daily" ? null : newAlert.day });
                }}
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
              </select>
            </div>
            {newAlert.frequency === "Weekly" ? (
              <div className="mb-6 flex justify-stretch w-full">
                <div className="w-full mr-3">
                  <label
                    for="day"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Day to receive alerts
                  </label>
                  <select
                    name="day"
                    id="day"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={newAlert.day}
                    onChange={(e) => {
                      setNewAlert({ ...newAlert, day: e.target.value });
                    }}
                  >
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </select>
                </div>
                <div className="w-full">
                  <label
                    for="time"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Time to receive alerts
                  </label>
                  <div className="flex bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <select
                      name="time"
                      id="time"
                      className="bg-transparent text-sm text-gray-900 appearance-none outline-none"
                      value={time}
                      onChange={(e) => {
                        setTime(e.target.value);
                        setNewAlert({
                          ...newAlert,
                          time: `${e.target.value}:00 ${ampmState}`,
                        });
                      }}
                    >
                      {[...Array(11).keys()].map((i) => (
                        <option value={i + 1}>{i + 1}:00</option>
                      ))}
                    </select>
                    <select
                      name="ampm"
                      class="bg-transparent text-sm text-gray-900 appearance-none outline-none"
                      value={ampm}
                      onChange={(e) => {
                        setAmpmState(e.target.value);
                        setNewAlert({
                          ...newAlert,
                          time: `${time}:00 ${e.target.value}`,
                        });
                      }}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-6">
                <label
                  for="time"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Time to receive alerts
                </label>
                <div className="flex bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                  <select
                    name="time"
                    id="time"
                    className="bg-transparent text-sm text-gray-900 appearance-none outline-none"
                    value={time}
                    onChange={(e) => {
                      setTime(e.target.value);
                      setNewAlert({
                        ...newAlert,
                        time: `${e.target.value}:00 ${ampmState}`,
                      });
                    }}
                  >
                    {[...Array(11).keys()].map((i) => (
                      <option value={i + 1}>{i + 1}:00</option>
                    ))}
                  </select>
                  <select
                    name="ampm"
                    class="bg-transparent text-sm text-gray-900 appearance-none outline-none"
                    value={ampm}
                    onChange={(e) => {
                      setAmpmState(e.target.value);
                      setNewAlert({
                        ...newAlert,
                        time: `${time}:00 ${e.target.value}`,
                      });
                    }}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            )}
            */}
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 block"
              onClick={() => {
                editView.type === "edit" && updateAlert(newAlert, false);
                editView.type === "duplicate" && checkDuplicate();
              }}
            >
              {editView.type === "edit" ? "Save" : "Add"}
            </button>
            {isDuplicate.state && (
              <Toast notified="duplicate" setToast={setIsDuplicate} />
            )}
          </div>
        </aside>
      </>
    );
};

export default EditPanel;
