import React, { Component } from "react";
import Nav from '../Shared/Nav';
import '../../index.css';

class Contact extends Component {
  render() {
    return (
      <div className="h-screen Contact">
        {/* body */}
        <header className="sticky top-0 z-50">
          <Nav loggedIn={this.props.loggedIn} username={this.props.username}/>
        </header>
        <main className="h-full relative flex flex-col justify-between">
          <section className="px-10 py-28 bg-white flex flex-col">
            <h1 className="text-theme-darkblue font-regular font-bold text-5xl">Join the beta</h1>
            <p className="pt-5 font-regular text-xl">Contact us to set up a beta account with Minutes at <a href="mailto:minutes@michiganpublic.org?subject=Minutes%20beta%20tester%20signup"><u>minutes@michiganpublic.org</u>.</a></p>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-gray">
            <div className="grid grid-rows-2">
              <div>
                <a className="pr-5 font-regular font-medium" href="mailto:minutes@michiganpublic.org?subject=Minutes%20beta%20tester%20signup">Contact Us</a>
                <div className="mt-5 flex lg:float-right lg:mt-0">
                  <a className="font-regular font-medium lg:px-5" href="https://michiganradio.secureallegiance.com/wuom/WebModule/Donate.aspx?P=SUSTAINER&PAGETYPE=PLG&CHECK=7xZmO7zqt1vzhRN6l0Ol+61gzMC6uhq5nDjkJobrCdg%3D"><u>Donate</u></a>
                  <img className="px-5" src="https://storage.googleapis.com/minutes-resources/facebook-square.svg" alt="..."></img>
                  <img className="px-5" src="https://storage.googleapis.com/minutes-resources/linkedin.svg" alt="..."></img>
                  <img className="pl-5" src="https://storage.googleapis.com/minutes-resources/twitter.svg" alt="..."></img>
                </div>
              </div>
              <div className="pt-5 font-regular">
                ©2022 Minutes. All rights reserved. Minutes is supported by Michigan Public, an NPR Member Station and a public media service of The University of Michigan.
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Contact;
