import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import About from "../About/About";
import Contact from "../Contact/Contact";
import MultiContact from "../Contact/MultiContact";
import Homepage from "../Homepage/Homepage";
import Login from "../Login/Login";
import Search from "../Search/Search";
// import TestLandingPage from "../Search/TestLandingPage";
import MichiganRadioPage from "../Search/MichiganRadioPage";
import Notification from "../Notification/Notification";


function getToken() {
  const userToken = localStorage.getItem('token');

  return userToken
}

async function checkToken(userToken) {
  return fetch('/token-check/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "token": userToken })
  })
  .then(data => data.json())
}

function checkLogin(response) {
  let currentUser = null
  let isLoggedIn = false

  try {
    currentUser = response.user.username
    isLoggedIn = true

    return [currentUser, isLoggedIn]
  } catch (e) {
    return [currentUser, isLoggedIn]
  }
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      token: '',
      username: ''
    }
  }

  async componentDidMount() {
    const userToken = getToken()
    const response = await checkToken(userToken)

    const checkedData = checkLogin(response)
    const currentUser = checkedData[0]
    const isLoggedIn = checkedData[1]

    this.setState({
      loggedIn: isLoggedIn,
      token: userToken,
      username: currentUser
    })
  }

  render() {
    if(!this.state.loggedIn && !(
        window.location.pathname === "/"
        || window.location.pathname === "/about"
        || window.location.pathname === "/contact"
        || window.location.pathname === "/login"
        || window.location.pathname === "/search/michiganradio"
    )) {
      return (
        <Login loggedIn={this.state.loggedIn} username={this.state.username} path={window.location.pathname}/>
      )
    }

    return (
      <div>
        <Router>
         <Route exact path="/" component={() => <Homepage loggedIn={this.state.loggedIn} username={this.state.username} />} />
         <Route exact path="/about" component={() => <About loggedIn={this.state.loggedIn} username={this.state.username} />} />
         <Route exact path="/contact" component={() => <MultiContact loggedIn={this.state.loggedIn} username={this.state.username} />} />
         <Route exact path="/login" component={() => <Login loggedIn={this.state.loggedIn} username={this.state.username} path={window.location.pathname}/>} />
         <Switch>
              <Route exact path="/search" component={() => <Search loggedIn={this.state.loggedIn} username={this.state.username} />} />
              <Route exact path="/search/michiganradio" component={() => <MichiganRadioPage loggedIn={this.state.loggedIn} username={this.state.username} />} />
         </Switch>
         <Route exact path="/settings" component={() => <Notification loggedIn={this.state.loggedIn} username={this.state.username} />} />
        </Router>
      </div>
    )
  }
}

export default App;
