import React, { Component } from 'react';

class Nav extends Component {

  handleLogout() {
    localStorage.removeItem('token')
  }

  render() {

    // when you use nav, make sure to pass the loggedIn and username
    if (this.props.loggedIn) {
      return (
        <nav className="mb-14 w-full h-14 bg-white z-100 flex-col sm:grid sm:grid-cols-2 sm:mb-0">
          <a href="/" className="h-full px-4 py-1.5 align-middle float-left inline-block font-regular font-medium text-4xl text-theme-darkblue">minutes</a>
          <div className="h-full w-full flex justify-between bg-theme-gray sm:min-w-fit sm:flex-none sm:justify-end sm:float-right sm:bg-white">
            <a href="/about" className="h-full px-5 py-3.5 font-regular font-semibold text-md text-black hover:text-theme-blue">About</a>
            <a href="/contact" className="h-full px-5 py-3.5 font-regular font-semibold text-md text-black hover:text-theme-blue">Contact</a>
            <a href="/search" className="h-full px-5 py-3.5 font-regular font-semibold text-md text-black hover:text-theme-blue">Search</a>
            <a href="/settings" className="h-full px-5 py-3.5 font-regular font-semibold text-md text-black hover:text-theme-blue">Settings</a>
            <a href="/" className="h-full px-7 py-3.5 bg-theme-yellow font-regular font-semibold whitespace-nowrap" onClick={this.handleLogout}>Log Out</a>
          </div>
        </nav>
      )
    }

    return (
      <nav className="mb-14 w-full h-14 bg-white z-100 flex-col sm:grid sm:grid-cols-2 sm:mb-0">
        <a href="/" className="h-full px-4 py-1.5 align-middle float-left inline-block font-regular font-medium text-4xl text-theme-darkblue">minutes</a>
        <div className="h-full w-full flex justify-between bg-theme-gray sm:min-w-fit sm:flex-none sm:justify-end sm:float-right sm:bg-white">
          <a href="/about" className="h-full px-5 py-3.5 font-regular font-semibold text-md text-black hover:text-theme-blue">About</a>
          <a href="/contact" className="h-full px-5 py-3.5 font-regular font-semibold text-md text-black hover:text-theme-blue">Contact</a>
          <a href="/login" className="h-full w-auto px-5 py-3.5 font-regular font-semibold text-md text-black whitespace-nowrap hover:text-theme-blue">Log In</a>
          <a href="/contact" className="h-full px-7 py-3.5 bg-theme-yellow font-regular font-semibold whitespace-nowrap">Sign Up</a>
        </div>
      </nav>
    )
  }
}

export default Nav;
