import React, { useState } from "react";
import Nav from '../Shared/Nav';
import { getCookie } from "../../getCookie";
import '../../index.css';

const MultiContact = ({ loggedIn, username }) => {
  const [formType, setFormType] = useState('');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    if (!formType) {
      setError('Please select a form type');
      return;
    }
    setLoading(true);
    try {
      const response = await fetch('/user_auth/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify({ 
          formType, 
          ...formData,
          user: localStorage.getItem('token')
        }),
      });
      const data = await response.json();
      if (response.status === 401) {
        setError('Your session has expired. Please log in again.');
      } else if (response.ok) {
        setSuccess('Your message has been sent successfully.');
        setFormData({});
        setFormType('');
      } else {
        setError(data.message || 'An error occurred while sending your message.');
      }
    } catch (error) {
      setError('Failed to communicate with the server.');
    }
    setLoading(false);
  };

  const renderFormFields = () => {
    switch (formType) {
      case 'newLocality':
        return (
          <>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Locality Name</th>
              <td className="px-6 py-4">
                <input
                  type="text"
                  name="localityName"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Video Channel</th>
              <td className="px-6 py-4">
                <input
                  type="text"
                  name="videoChannel"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Website</th>
              <td className="px-6 py-4">
                <input
                  type="url"
                  name="website"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  onChange={handleInputChange}
                />
              </td>
            </tr>
          </>
        );
      case 'storyNotification':
        return (
          <>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Story Details</th>
              <td className="px-6 py-4">
                <textarea
                  name="storyDetails"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  rows="4"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </td>
            </tr>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Published Story URL</th>
              <td className="px-6 py-4">
                <input
                  type="url"
                  name="storyUrl"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
          </>
        );
      case 'troubleTicket':
        return (
          <>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Issue Description</th>
              <td className="px-6 py-4">
                <textarea
                  name="issueDescription"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  rows="4"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </td>
            </tr>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Steps to Reproduce</th>
              <td className="px-6 py-4">
                <textarea
                  name="stepsToReproduce"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  rows="4"
                  onChange={handleInputChange}
                ></textarea>
              </td>
            </tr>
          </>
        );
      case 'generalFeedback':
        return (
          <>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Feedback</th>
              <td className="px-6 py-4">
                <textarea
                  name="feedback"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  rows="4"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </td>
            </tr>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Expect Follow-up?</th>
              <td className="px-6 py-4">
                <select
                  name="expectFollowup"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </td>
            </tr>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-screen Contact">
      <header className="sticky top-0 z-50">
        <Nav loggedIn={loggedIn} username={username}/>
      </header>
      <main className="h-full relative flex flex-col justify-between">
        <section className="px-10 py-28 bg-white flex flex-col">
          <h1 className="text-theme-darkblue font-regular font-bold text-5xl">Contact Us</h1>
          {loggedIn ? (
            <div>
              <p className="pt-5 font-regular">Let us know if you need help. This form allows you to:</p>
              <ol className="list-decimal pl-8 pt-2 font-regular">
                <li>Request a new locality: If you'd like Minutes to monitor a new local government meeting feed, give us the details and we'll work on it!</li>
                <li>Story notification: Have you published a story using Minutes? Let us know so we can share promote your usage with potential users of Minutes.</li>
                <li>Trouble ticket: Darn it. We're sorry the experience isn't perfect yet. Let us know how we can improve.</li>
                <li>General feedback: Tell us what's on your mind about Minutes.</li>
              </ol>
              <form onSubmit={handleSubmit} className="mt-10">
                <table className="w-full text-sm text-left text-gray-500">
                  <tbody>
                    <tr className="bg-gray-50 border-b border-gray-200">
                      <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Form Type</th>
                      <td className="px-6 py-4">
                        <select
                          className="border border-gray-300 rounded-md py-2 px-3 w-full"
                          value={formType}
                          onChange={(e) => setFormType(e.target.value)}
                          required
                        >
                          <option value="">Select form type</option>
                          <option value="newLocality">New Locality Request</option>
                          <option value="storyNotification">Story Notification</option>
                          <option value="troubleTicket">Trouble Ticket</option>
                          <option value="generalFeedback">General Feedback</option>
                        </select>
                      </td>
                    </tr>
                    {renderFormFields()}
                  </tbody>
                </table>
                
                {error && (
                  <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="mt-4 p-3 bg-green-100 border border-green-400 text-green-700 rounded">
                    {success}
                  </div>
                )}
                
                <div className="mt-6">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    disabled={loading}
                  >
                    {loading ? 'Sending...' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <p className="pt-5 font-regular text-xl">Contact us to set up a beta account with Minutes at <a href="mailto:minutes@michiganpublic.org?subject=Minutes%20beta%20tester%20signup"><u>minutes@michiganpublic.org</u>.</a></p>
          )}
        </section>
        <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-gray">
          <div className="grid grid-rows-2">
            <div>
              <a className="pr-5 font-regular font-medium" href="mailto:minutes@michiganpublic.org?subject=Minutes%20Inquiry">Email Us</a>
              <div className="mt-5 flex lg:float-right lg:mt-0">
                <a className="font-regular font-medium lg:px-5" href="https://michiganradio.secureallegiance.com/wuom/WebModule/Donate.aspx?P=SUSTAINER&PAGETYPE=PLG&CHECK=7xZmO7zqt1vzhRN6l0Ol+61gzMC6uhq5nDjkJobrCdg%3D"><u>Donate</u></a>
                <img className="px-5" src="https://storage.googleapis.com/minutes-resources/facebook-square.svg" alt="Facebook"></img>
                <img className="px-5" src="https://storage.googleapis.com/minutes-resources/linkedin.svg" alt="LinkedIn"></img>
                <img className="pl-5" src="https://storage.googleapis.com/minutes-resources/twitter.svg" alt="Twitter"></img>
              </div>
            </div>
            <div className="pt-5 font-regular">
              ©2024 Minutes. All rights reserved. Minutes is supported by Michigan Public, an NPR Member Station and a public media service of The University of Michigan.
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default MultiContact;
