const Sidebar = ({ change, page }) => {
  return (
    <aside
      id="default-sidebar"
      className="fixed top-50 left-0 z-40 w-16 md:w-64 h-screen transition-transform translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 py-4 overflow-y-auto bg-theme-blue">
        <ul className="space-y-2 font-medium">

          <li>
            <a
              onClick={() => {
                change("profile");
              }}
              href="#profile"
              className={`${
                page === "profile" && "bg-gray-100"
              } flex items-center p-2 text-black-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.685 19.097C19.654 18.1865 20.426 17.0869 20.9532 15.8662C21.4803 14.6455 21.7515 13.3297 21.75 12C21.75 6.615 17.385 2.25 12 2.25C6.61501 2.25 2.25001 6.615 2.25001 12C2.24847 13.3297 2.51967 14.6455 3.04686 15.8662C3.57404 17.0869 4.346 18.1865 5.31501 19.097C7.12194 20.8039 9.51435 21.7533 12 21.75C14.4857 21.7533 16.8781 20.8039 18.685 19.097ZM6.14501 17.812C6.84708 16.9336 7.73802 16.2247 8.75164 15.7379C9.76527 15.2511 10.8755 14.9989 12 15C13.1245 14.9989 14.2347 15.2511 15.2484 15.7379C16.262 16.2247 17.1529 16.9336 17.855 17.812C17.089 18.5857 16.1771 19.1996 15.172 19.6181C14.1669 20.0366 13.0887 20.2514 12 20.25C10.9113 20.2514 9.83311 20.0366 8.82803 19.6181C7.82296 19.1996 6.91098 18.5857 6.14501 17.812ZM15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75C11.0054 12.75 10.0516 12.3549 9.34836 11.6517C8.64509 10.9484 8.25001 9.99456 8.25001 9C8.25001 8.00544 8.64509 7.05161 9.34836 6.34835C10.0516 5.64509 11.0054 5.25 12 5.25C12.9946 5.25 13.9484 5.64509 14.6517 6.34835C15.3549 7.05161 15.75 8.00544 15.75 9Z"
                  fill="black"
                />
              </svg>
              <span className="hidden md:ml-3 md:inline">Profile</span>
            </a>
          </li>

          <li>
            <a
              onClick={() => {
                change("password");
              }}
              href="#password"
              className={`${
                page === "password" && "bg-gray-100"
              } flex items-center p-2 text-black-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 4.5C11.2044 4.5 10.4413 4.81607 9.87868 5.37868C9.31607 5.94129 9 6.70435 9 7.5V9H15V7.5C15 6.70435 14.6839 5.94129 14.1213 5.37868C13.5587 4.81607 12.7956 4.5 12 4.5ZM16.5 9V7.5C16.5 6.30653 16.0259 5.16193 15.182 4.31802C14.3381 3.47411 13.1935 3 12 3C10.8065 3 9.66193 3.47411 8.81802 4.31802C7.97411 5.16193 7.5 6.30653 7.5 7.5V9H6C5.60218 9 5.22064 9.15804 4.93934 9.43934C4.65804 9.72064 4.5 10.1022 4.5 10.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V10.5C19.5 10.1022 19.342 9.72064 19.0607 9.43934C18.7794 9.15804 18.3978 9 18 9H16.5Z"
                  fill="black"
                />
              </svg>
              <span className="hidden md:ml-3 md:inline">Password</span>
            </a>
          </li>

          <li>
            <a
              onClick={() => {
                change("alerts");
              }}
              href="#alerts"
              className={`${page==="alerts"&&"bg-gray-100"} flex items-center p-2 text-black-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.24973 9C5.24973 7.20979 5.96089 5.4929 7.22676 4.22703C8.49263 2.96116 10.2095 2.25 11.9997 2.25C13.7899 2.25 15.5068 2.96116 16.7727 4.22703C18.0386 5.4929 18.7497 7.20979 18.7497 9V9.75C18.7497 11.873 19.5497 13.807 20.8677 15.27C20.9498 15.361 21.0084 15.4707 21.0383 15.5896C21.0682 15.7084 21.0685 15.8328 21.0391 15.9518C21.0098 16.0708 20.9518 16.1808 20.8702 16.2722C20.7885 16.3636 20.6857 16.4335 20.5707 16.476C19.0267 17.046 17.4107 17.466 15.7397 17.719C15.7774 18.2331 15.7086 18.7495 15.5377 19.2359C15.3668 19.7222 15.0974 20.1681 14.7464 20.5457C14.3955 20.9233 13.9704 21.2245 13.4978 21.4304C13.0252 21.6364 12.5152 21.7427 11.9997 21.7427C11.4842 21.7427 10.9742 21.6364 10.5016 21.4304C10.029 21.2245 9.60399 20.9233 9.25302 20.5457C8.90205 20.1681 8.6327 19.7222 8.4618 19.2359C8.29089 18.7495 8.22211 18.2331 8.25973 17.719C6.61138 17.4692 4.99272 17.0524 3.42873 16.475C3.31386 16.4326 3.21113 16.3627 3.12949 16.2715C3.04786 16.1802 2.98981 16.0703 2.96041 15.9515C2.93101 15.8326 2.93117 15.7084 2.96086 15.5896C2.99055 15.4708 3.04887 15.3611 3.13073 15.27C4.49754 13.7567 5.25281 11.7892 5.24973 9.75V9ZM9.75173 17.9C9.73894 18.2032 9.78761 18.5058 9.89481 18.7897C10.002 19.0736 10.1655 19.3329 10.3756 19.5519C10.5856 19.771 10.8377 19.9453 11.1168 20.0644C11.3959 20.1835 11.6963 20.2448 11.9997 20.2448C12.3032 20.2448 12.6035 20.1835 12.8826 20.0644C13.1618 19.9453 13.4139 19.771 13.6239 19.5519C13.8339 19.3329 13.9974 19.0736 14.1046 18.7897C14.2119 18.5058 14.2605 18.2032 14.2477 17.9C12.7521 18.0347 11.2474 18.0347 9.75173 17.9Z"
                  fill="black"
                />
              </svg>

              <span className="hidden md:ml-3 md:inline">Alerts</span>
            </a>
          </li>

          <li>
            <a
              onClick={() => {
                change("groups");
              }}
              href="#groups"
              className={`${page==="groups"&&"bg-gray-100"} flex items-center p-2 text-black-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.25 6.75C8.25 8.54 6.79 10 5 10C3.21 10 1.75 8.54 1.75 6.75C1.75 4.96 3.21 3.5 5 3.5C6.79 3.5 8.25 4.96 8.25 6.75ZM15 10C16.79 10 18.25 8.54 18.25 6.75C18.25 4.96 16.79 3.5 15 3.5C13.21 3.5 11.75 4.96 11.75 6.75C11.75 8.54 13.21 10 15 10ZM5 11.5C2.83 11.5 1 13.33 1 15.5V17C1 17.55 1.45 18 2 18H8C8.55 18 9 17.55 9 17V15.5C9 13.33 7.17 11.5 5 11.5ZM15 11.5C12.83 11.5 11 13.33 11 15.5V17C11 17.55 11.45 18 12 18H18C18.55 18 19 17.55 19 17V15.5C19 13.33 17.17 11.5 15 11.5Z"
                  fill="black"
                />
              </svg>
              <span className="hidden md:ml-3 md:inline">Groups</span>
            </a>
          </li>

        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
