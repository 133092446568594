import React, { useState, useEffect } from "react";
import FullTranscript from "./FullTranscript";
import MeetingVideo from "./MeetingVideo";
import Drawer from 'react-modern-drawer';
import Highlighter from 'react-highlight-words';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';

import 'react-modern-drawer/dist/index.css'
import '../../index.css';

const ResultDetail = ({ resultdata, open, closeDrawer, id, currentDrawer, month_names, searchTerm}) => {

  const [drawerStatus, setDrawerStatus] = useState(false);

  useEffect(() => {
    setDrawerStatus(open)
  }, [open]);

  function getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;
  }

  if (currentDrawer === id) {
    
    return (
      <>
        <Drawer size="90vw"
                direction="right"
                open={drawerStatus}
                onClose={closeDrawer}
                lockBackgroundScroll={true}
                className="">
          <main className="h-full flex flex-row justify-between">
            <div className="w-1/12 flex flex-row">
              <div className="w-1/6 bg-theme-blue"></div>
              <div className="w-5/6 px-3"></div>
            </div>
            <div className="flex flex-col md:w-5/6 md:px-10 py-28 bg-white md:flex-row">
              <div className="md:h-screen md:w-1/8 md:px-4 py-4">
                <button type="button"
                        class="mr-1 text-black-700 border border-black hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                        onClick={closeDrawer} >
                  <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" transform="scale(-1, 1)"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  <span class="sr-only">Back to search results</span>
                </button>
              </div>
              <section className="md:w-7/8 md:px-10 bg-white flex flex-col overflow-y-scroll">
                <div className="flex flex-col items-center justify-center w-2/3">
                  <MeetingVideo 
                    resultdata={resultdata} 
                    month_names={month_names}
                    showHeading={true}
                  />
                </div>
                <div className="mt-8 flex flex-row">
                  <div className="h-full mx-3 px-1 bg-theme-blue"></div>
                  <p className="ml-2 text-theme-black font-regular">
                    {resultdata.transcript.raw === '0' ? (
                        <i>Transcript unavailable.</i>
                    ) : resultdata.segment.raw !== '0' ? (
                        <Highlighter
                          searchWords={searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                          autoEscape={true}
                          textToHighlight={"..." + resultdata.transcript.raw.replaceAll('"','').replaceAll('&nbsp','')}
                        />
                    ) : (
                        <Highlighter
                          searchWords={searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                          autoEscape={true}
                          textToHighlight={resultdata.transcript.raw.charAt(0).replaceAll('"','').replaceAll('&nbsp','').toUpperCase() + resultdata.transcript.raw.slice(1).replaceAll('"','').replaceAll('&nbsp','')}
                        />
                    )}
                  </p>
                </div>
                <Accordion allowZeroExpanded className="mt-10">
                  <AccordionItem className="mb-8">
                      <AccordionItemHeading className="pb-5 border-b-4">
                          <AccordionItemButton className="text-theme-darkblue font-regular text-xl">
                            <div className="flex justify-between items-center">
                            <div className="ml-2">Full Transcript</div>
                            <AccordionItemState>
                              {({ expanded }) => (expanded ?
                                <svg className="mr-4" fill="none" width="25px" height="25px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
                                </svg>
                                :
                                <svg className="mr-4" fill="none" width="25px" height="25px" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                                </svg>
                              )}
                            </AccordionItemState>
                            </div>
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="mt-5">
                          <FullTranscript resultdata={resultdata} searchTerm={searchTerm} />
                      </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </section>
            </div>
            <div className="w-1/12 px-3"></div>
          </main>
        </Drawer>
      </>
    );
  }
  else {
    return null;
  }
};
export default ResultDetail;