import React, { Component }  from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import Nav from '../Shared/Nav';
import Search from '../Search/Search';
import Notification from '../Notification/Notification';

function getToken() {
  const userToken = localStorage.getItem('token');

  return userToken
}

async function checkToken(userToken) {
  return fetch('/token-check/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "token": userToken })
  })
  .then(data => data.json())
}

function checkLogin(response) {
  let currentUser = null
  let isLoggedIn = false

  try {
    currentUser = response.user.username
    isLoggedIn = true

    return [currentUser, isLoggedIn]
  } catch (e) {
    return [currentUser, isLoggedIn]
  }
}

async function loginUser(credentials) {
  return fetch('/token-auth/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

async function getAuthtoken(credentials) {
  return fetch('/token/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

class Login extends Component {

  // make sure to call the Login componenet with a loggedIn prop
  constructor(props) {
      super(props);
      this.state = {
        loggedIn: this.props.loggedIn,
        password: '',
        username: this.props.username,
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const userToken = getToken()
    const response = await checkToken(userToken)

    const checkedData = checkLogin(response)
    const currentUser = checkedData[0]
    const isLoggedIn = checkedData[1]

    this.setState({
      failureMessage: '',
      loggedIn: isLoggedIn,
      username: currentUser,
    })
  }

  handleChange (field, value) {
    this.setState({ [field]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const username = this.state.username;
    const password = this.state.password;

    const data = await loginUser({ username, password });
    const user_token = await getAuthtoken({ username, password });
    if (data.token !== undefined) {
      localStorage.setItem('token', data.token)
      localStorage.setItem('auth-token',user_token.token)
      console.log('auth-token:', user_token.token)
      this.setState({ loggedIn: true})
    } else {
      this.setState({ failureMessage: 'Oops - something doesn\'t look right. Try again.' })
    }
  }

  render() {
    if(this.state.loggedIn) {
      return(
        <Router>
          <Redirect push to={this.props.path === '/login'? '/search': `${this.props.path}`} />
          <Route exact path="/search" component={() => <Search loggedIn={this.state.loggedIn} username={this.state.username}/>} />
          <Route exact path="/alerts" component={() => <Notification loggedIn={this.state.loggedIn} username={this.state.username}/>} />
        </Router>
      )
    }

    return(
      <div>
        <header className="sticky top-0">
          <Nav loggedIn={this.state.loggedIn} username={this.state.username}/>
        </header>
        <main>
          <div className="h-screen bg-theme-lightblue border-0 border-t border-theme-darkgray flex justify-center">
            <section className="mx-5 sm:mx-0 py-28">
              <form class="bg-white border border-black" onSubmit={this.handleSubmit}>
                <h1 className="px-10 pt-10 font-regular font-medium text-theme-darkblue text-6xl">Log In</h1>
                <div class="px-10 py-5">
                  <label className="" for="username">
                    <p className="pt-5 pb-2 font-regular font-light text-theme-darkgray tracking-wide">EMAIL</p>
                  </label>
                  <input class="border border-black w-full py-2 px-3 leading-tight font-regular font-thin placeholder-s placeholder-black" id="username" type="text" placeholder="Email" onChange={e => this.handleChange('username', e.target.value)}/>
                </div>
                <div class="px-10">
                  <label for="password">
                    <p className="py-2 font-regular font-light text-theme-darkgray tracking-wide">PASSWORD</p>
                  </label>
                  <input class="border border-black w-full py-2 px-3 leading-tight font-thin placeholder-s placeholder-black" id="password" type="password" placeholder="**********" onChange={e => this.handleChange('password', e.target.value)}/>
                  <div className="mt-2 text-theme-darkblue">{ this.state.failureMessage }</div>
                </div>
                <div className="px-10 py-10">
                  <button className="px-7 py-3 bg-theme-yellow align-middle font-regular font-bold text-xl" type="submit">
                    Sign In
                  </button>
                </div>
                <div className="px-10 py-10 font-regular text-l">
                  Don’t have a login? <a href="/contact"><u>Contact us</u></a> to set up beta access.
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    )
  }
}

export default Login;
